html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root{
  background-color: #095367;
  background-image: linear-gradient(90deg,rgba(63,46,135,.7),rgba(73,160,199,.8)),url('https://assets.website-files.com/5fc92c936a424a24a1923525/602ee74cb85e3657557b5254_Pattern-Repeatable.png');
}